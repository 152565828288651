import axios from "axios";
import { apiBaseUrl } from "./apis";
import axiosRetry from "axios-retry";
import logger from "../utils/logger";

import JSEncrypt from "jsencrypt";

const apiNodeBaseUrl = "https://genwescoring.thegenwe.com"


const apiInstance = () => {
  const api = axios.create({
    baseURL: apiBaseUrl,
  });
  axiosRetry(api, { retries: 3 });

  api.interceptors.request.use(async (config) => {

    const encryptData = (data, publicKey) => {
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      const encrypted = encryptor.encrypt(data);
      return encrypted;
    };

    let publicKey = `-----BEGIN PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCGmL1KH5O+iBNNkG+my8YGDmt8Eeenude5n5t+n05aILJTORFqyGHKuwR9Zvit/0iBqiLeDWlyj3/twfZWytrstvFu6AYpnE55QXB8N8HtSCW8wE7eHeCkHWVECCoWYlaiCjEaJbh1ZqTJV4HmWpi8kKX0bb++BgXTHv7Z5hY5jwIDAQAB
   -----END PUBLIC KEY-----`

    const data = `${Date.now()}`
    const apiKey = encryptData(data, publicKey)

    let accessToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjkxODg4MTgzMTgzNiIsImlhdCI6MTYxMDkwMjA4MX0.ynch4YzvjPJL6Jl1pqng5lYLtiN1xZkrBiHex7NaOtA";
    if (accessToken) {
      if (config.method !== "OPTIONS") {
        config.headers.authorization = `Bearer ${accessToken}`;
      }

      config.headers = { ...config.headers, "x-api-key": apiKey, "x-encrypt": 'true' };
    }
    logger.log("REQUEST", config);
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      logger.log("RESPONSE", response);
      return response;
    },
    (error) => {
      logger.log("ERROR", error, error.response);
      //throw error.response;
      throw error;
    }
  );

  return api;
};

const apiClient = apiInstance();

const apiNodeInstance = () => {
  const api = axios.create({
    baseURL: apiNodeBaseUrl,
  });
  axiosRetry(api, { retries: 3 });

  api.interceptors.request.use(async (config) => {


    const encryptData = (data, publicKey) => {
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      const encrypted = encryptor.encrypt(data);
      return encrypted;
    };

    let publicKey = `-----BEGIN PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCGmL1KH5O+iBNNkG+my8YGDmt8Eeenude5n5t+n05aILJTORFqyGHKuwR9Zvit/0iBqiLeDWlyj3/twfZWytrstvFu6AYpnE55QXB8N8HtSCW8wE7eHeCkHWVECCoWYlaiCjEaJbh1ZqTJV4HmWpi8kKX0bb++BgXTHv7Z5hY5jwIDAQAB
   -----END PUBLIC KEY-----`

    const data = `${Date.now()}`
    const apiKey = encryptData(data, publicKey)

    let accessToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjkxODg4MTgzMTgzNiIsImlhdCI6MTYxMDkwMjA4MX0.ynch4YzvjPJL6Jl1pqng5lYLtiN1xZkrBiHex7NaOtA";
    if (accessToken) {
      if (config.method !== "OPTIONS") {
        config.headers.authorization = `Bearer ${accessToken}`;
      }

      config.headers = { ...config.headers, "x-api-key": apiKey, "x-encrypt": 'true' };
    }
    logger.log("REQUEST", config);
    return config;
  })

  return api
}
const apiNodeClient = apiNodeInstance()

const moengageApiClient = (params) => {
  const { type, body = {} } = params;
  const token = Buffer.from(`6KIBHYUWIG7V3DEQPZPYWQB2_DEBUG:b_z8o1t7xFlKT8HQ44_CbDwf`, 'utf8').toString('base64')
  axios.post(
    `https://api-03.moengage.com/v1/${type}/6KIBHYUWIG7V3DEQPZPYWQB2_DEBUG`,
    body,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        // auth: {
        //   username: '6KIBHYUWIG7V3DEQPZPYWQB2_DEBUG',
        //   password: 'b_z8o1t7xFlKT8HQ44_CbDwf'
        // }
        Authorization: `Basic ${token}`
        // "Basic 6KIBHYUWIG7V3DEQPZPYWQB2_DEBUG:b_z8o1t7xFlKT8HQ44_CbDwf",
      },
    }
  );
};
export { moengageApiClient, apiNodeClient };
export default apiClient;
