import React, { useState } from "react";
import axios from "axios";
import { Button, TextField, Select, MenuItem, Typography } from "@material-ui/core";

const EditContentModal = ({ contentId, onClose }) => {
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    // Fetch content by ID
    const fetchContent = async () => {
        try {
            const response = await axios.get(`https://genwescoring.thegenwe.com/scraper/getcontent/${contentId}`);
            setContent(response.data.data);
        } catch (error) {
            console.error("Error fetching content:", error);
        } finally {
            setLoading(false);
        }
    };

    // Update content
    const updateContent = async () => {
        try {
            setButtonLoading(true);
            await axios.put(`https://genwescoring.thegenwe.com/scraper/updatecontent/${contentId}`, {
                category: content.category,
                title: content.title,
                description: content.description,
                imageUrl: content.imageUrl,
            });
            setButtonLoading(false);
            alert("Content updated successfully!");
            onClose();
        } catch (error) {
            setButtonLoading(false);
            console.error("Error updating content:", error);
        }
    };
    const onReject = async () => {
        try {
            setButtonLoading(true);
            await axios.put(`https://genwescoring.thegenwe.com/scraper/rejectcontent/${contentId}`);
            setButtonLoading(false);
            onClose();
        } catch (error) {
            setButtonLoading(false);
            console.error("Error updating content:", error);
        }
    }

    // Handle input changes
    const handleChange = (field, value) => {
        setContent((prevContent) => ({
            ...prevContent,
            [field]: value,
        }));
    };

    // Fetch content on mount
    React.useEffect(() => {
        fetchContent();
    }, []);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (

        <div style={{ display: "flex", borderRadius: 20, marginTop: 100, marginBottom: 50, marginRight: 20, marginLeft: 20, padding: 40, marginBottom: 150, gap: 20, margin: "auto", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>

            <div style={{ flex: 1, marginRight: "1rem", }}>
                <div
                    style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        height: 0,
                        overflow: "hidden",
                        borderRadius: "4px",
                        background: "#000",
                    }}
                >
                    <iframe
                        src={`https://www.youtube.com/embed/${content.id}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Video"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </div>
            </div>


            <div style={{ flex: 1 }}>
                <Typography variant="h6" style={{ marginBottom: "1rem" }}>Edit Content</Typography>
                <TextField
                    label="Video Title"
                    value={content.title}
                    onChange={(e) => handleChange("title", e.target.value)}
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "1rem" }}
                />
                <TextField
                    label="Description"
                    value={content.description}
                    onChange={(e) => handleChange("description", e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    style={{ marginBottom: "1rem" }}
                />
                <Select
                    value={content.category}
                    onChange={(e) => handleChange("category", e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{ marginBottom: "1rem" }}
                >
                    <MenuItem value="Mental Health">Mental Health</MenuItem>
                    <MenuItem value="Bullying">Bullying</MenuItem>
                    <MenuItem value="Academic Pressure">Academic Pressure</MenuItem>
                    <MenuItem value="Peer Pressure">Peer Pressure</MenuItem>
                    <MenuItem value="Discrimination">Discrimination</MenuItem>
                    <MenuItem value="Family">Family</MenuItem>
                    <MenuItem value="Testing">Testing</MenuItem>
                </Select>
                {/* <TextField
                    label="Thumbnail URL"
                    value={content.imageUrl}
                    onChange={(e) => handleChange("imageUrl", e.target.value)}
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "1rem" }}
                /> */}
                <div style={{ display: "flex", gap: "1rem" }}>


                    <Button
                        variant="contained"
                        style={{ backgroundColor: "#4caf50", color: "#fff" }}
                        onClick={updateContent}
                    >
                        {buttonLoading ? "Loading..." : "Approve"}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={onReject}
                        style={{ backgroundColor: "#D64E4E", color: "#fff" }}
                    >{buttonLoading ? "Loading..." : "Reject"}

                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => onClose()}
                        style={{ backgroundColor: "gray", color: "#fff" }}
                    >Close

                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditContentModal;
