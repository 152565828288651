import React, { useState, useEffect } from "react";
import axios from "axios";
import MenuAppBar from "../../routes/header";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TablePagination from "@material-ui/core/TablePagination";
import EditContentModal from "./EditContentModal";

const WebCrawlerPage = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState("Mental Health");
    const [type, setType] = useState("video");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 1 });
    const [editContentId, setEditContentId] = useState(null);

    const fetchData = async (category, type, page, limit) => {
        try {
            setLoading(true);
            const response = await axios.get(
                `https://genwescoring.thegenwe.com/scraper/getpending`,
                {
                    params: {
                        category,
                        type,
                        page: page + 1, // Adjust API call for 1-based indexing
                        limit,
                    },
                }
            );
            setVideos(response.data.data);
            setPagination(response.data.pagination);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(category, type, page, rowsPerPage);
    }, [category, type, page, rowsPerPage, editContentId]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return <div style={{ textAlign: "center", padding: "2rem" }}>Loading...</div>;
    }



    // Update content
    const updateContent = async (contentId, content) => {
        try {

            await axios.put(`https://genwescoring.thegenwe.com/scraper/updatecontent/${contentId}`, {
                category: content.category,
                title: content.title,
                description: content.description,
                imageUrl: content.imageUrl,
            }).then((response) => {
                fetchData(category, type, page, rowsPerPage);
                alert("Content Updated Successfully");
            })


        } catch (error) {

            console.error("Error updating content:", error);
        }
    };
    const onReject = async (contentId) => {
        try {

            await axios.put(`https://genwescoring.thegenwe.com/scraper/rejectcontent/${contentId}`).then((response) => {
                fetchData(category, type, page, rowsPerPage);
                alert("Content Rejected Successfully");
            })


        } catch (error) {

            console.error("Error updating content:", error);
        }
    }



    return (
        <div style={{ fontFamily: "Arial, sans-serif", paddingBottom: 50 }}>
            <MenuAppBar heading="Web Crawler" />

            {editContentId ?

                (<div style={{ padding: "1rem", marginTop: 100 }} > <EditContentModal contentId={editContentId} onClose={() => setEditContentId(null)} /></div>)
                : <div style={{ padding: "1rem", marginTop: 100 }}>
                    {/* Filters */}
                    <div style={{ marginBottom: "1rem", gap: 10, display: "flex", alignItems: "center" }}>

                        <Select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            variant="outlined"
                            style={{ width: "200px" }} // Increased width for better fit
                            size="small"
                        >
                            <MenuItem value="Mental Health">Mental Health</MenuItem>
                            <MenuItem value="Bullying">Bullying</MenuItem>
                            <MenuItem value="Academic Pressure">Academic Pressure</MenuItem>
                            <MenuItem value="Peer Pressure">Peer Pressure</MenuItem>
                            <MenuItem value="Discrimination">Discrimination</MenuItem>
                            <MenuItem value="Family">Family</MenuItem>
                            <MenuItem value="Testing">Testing</MenuItem>
                        </Select>

                        <Select
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            variant="outlined"
                            style={{ width: "150px" }}
                            size="small"
                        >
                            <MenuItem value="video">Videos</MenuItem>
                            <MenuItem value="podcast">Podcast</MenuItem>

                        </Select>
                    </div>

                    {/* Content Table */}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sr. No.</TableCell>
                                    <TableCell>Thumbnail</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {videos.map((video, index) => (
                                    <TableRow key={video._id}>
                                        <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                        <TableCell>
                                            <img
                                                src={video.imageUrl}
                                                alt={video.title}
                                                style={{ width: "100px", borderRadius: "4px" }}
                                            />
                                        </TableCell>
                                        <TableCell>{video.title}</TableCell>
                                        <TableCell>{video.description || "No description available"}</TableCell>
                                        <TableCell>{video.category}</TableCell>
                                        <TableCell style={{}}>
                                            <div style={
                                                { flexDirection: "row", display: "flex", justifyContent: 'center', alignItems: 'center', }
                                            }><Button
                                                onClick={() => setEditContentId(video._id)}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginRight: "0.5rem" }}
                                            >
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={() => updateContent(video._id, video)}
                                                    variant="contained"
                                                    color="secondary"
                                                    size="small"
                                                    style={{ marginRight: "0.5rem", backgroundColor: "#3FB570" }}
                                                >
                                                    Approve
                                                </Button>
                                                <Button
                                                    onClick={() => onReject(video._id)}
                                                    variant="contained"
                                                    style={{ backgroundColor: "#D64E4E", color: "white" }}
                                                    size="small"
                                                >
                                                    Reject
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination */}
                    <TablePagination
                        component="div"
                        count={pagination.totalPages * rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>}
        </div>
    );
};

export default WebCrawlerPage;
